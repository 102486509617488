:root {
  --s-textcolor: #000000;
  --s-textcolor-02: #2F1657;
  --s-textcolor-03: #D6294A;
  --s-sitecolor: #2F1657;
  --s-sitecolor-02: #DFFE52;
  --s-sitecolor-03: #D6294A;
  --s-linkcolor: #000000;
  --s-linkcolor-hov: #2F1657;
  --s-btn-bgcolor: transparent;
  --s-btn-color: #D6294A;
  --s-btn-border: #D6294A;
  --s-btn-border-hov: #D6294A;
  --s-btn-bgcolor-hov: #D6294A;
  --s-btn-color-hov: #FFFFFF;
  --s-btn-secondary-color: #D6294A;
  --s-pdf-color: #000000;
  --s-pdf-bgcolor-hov: #000000;
  --s-pdf-color-hov: #FFFFFF;
  --s-pdf-border: #000000;
  --s-slider-control-color: #2F1657;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.button:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 20px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-arrow-right-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.button:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  border-radius: 2000px;
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    border-color: var(--s-btn-border-hov);
    color: var(--s-btn-color-hov);
    background-size: 100% 100%;
  }
  .button:hover:before,
  .button:focus:before {
    background-color: var(--s-btn-color-hov);
  }
  .button:hover:after,
  .button:focus:after {
    width: 41px;
    background-color: var(--s-btn-color-hov);
  }
}
.button-pdf {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.button-pdf:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  width: 22px;
  height: 30px;
  transform: translateY(-50%);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-pdf-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
@media (hover: hover) and (pointer: fine) {
  .button-pdf:hover,
  .button-pdf:focus {
    color: var(--s-pdf-color-hov);
    background-size: 100% 100%;
  }
  .button-pdf:hover:before,
  .button-pdf:focus:before {
    background-color: var(--s-pdf-color-hov);
  }
}
.footarea {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.footpart {
  margin-left: 20px;
  margin-right: 20px;
}
.footpart--one {
  width: calc(100% / 1320 * 105);
  margin-right: auto;
}
.footpart--two {
  width: calc(100% / 1320 * 280);
  margin-left: 40px;
}
.footpart--three {
  width: calc(100% / 1320 * 280);
}
.footpart--four {
  width: calc(100% / 1320 * 280);
}
.footpart--five {
  width: calc(100% - 40px);
}
.area--one {
  width: 103.125%;
  margin-left: -1.5625%;
}
.area--one .unit {
  width: 96.96969697%;
  margin-left: 1.51515152%;
  margin-right: 1.51515152%;
}
.area--one .unitOne--1-1 .unit__body {
  width: 103.125%;
  margin-left: -1.51515152%;
}
.area--one .unitOne--1-1 .part {
  width: 96.96969697%;
  margin-left: 1.51515152%;
  margin-right: 1.51515152%;
}
.area--one .unitOne--1-1 .part.part--slim {
  width: 46.96969697%;
}
.area--one .unitOne--1-2 {
  width: 46.96969697%;
}
.area--one .unitOne--1-3 {
  width: 30.3030303%;
}
.area--one .unitOne--1-4 {
  width: 21.96969697%;
}
.area--one .unit--form,
.area--one .unitTwo {
  min-height: calc(var(--js-elementHeight) + 20px);
}
.area--one .unit--form .unit__content,
.area--one .unitTwo .unit__content {
  padding: 0 0 0 51.5625%;
  box-sizing: border-box;
}
.area--one .unitTwo .part:first-child {
  position: absolute;
  left: 0;
  width: 40.625%;
}
.area--one .unitThree .unit__body {
  padding: 0 0 0 51.5625%;
  box-sizing: border-box;
}
.area--one .unit--slider:first-child {
  margin-top: calc((var(--spaceTotal) * -3) + var(--spaceUnit));
}
.area--one .unit--slider:last-child {
  margin-bottom: calc((var(--spaceTotal) * -3) + var(--spaceUnit));
}
/*# sourceMappingURL=./screen-large.css.map */